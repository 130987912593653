import {
  DocumentSnapshot,
  DocumentReference,
  Timestamp,
} from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";
import Employee from "./Employees/Employee";
import * as ImageKit from "@/utils/ImageUtils";
import ActionHistory from "@/model/ActivationHistory";
import ActivationHistory from "@/model/ActivationHistory";
import SecureatIllustration from "./utils/SecureatIllustration";

export default class Store extends SecureatSnapshot {
  active: boolean = false;
  address: string = "";
  city: string = "";
  creation_date: Date = new Date();
  database: DocumentReference | null = null;
  distributor: DocumentReference;
  // groups ?
  name: string = "";
  pairing_key: string | null = "";
  postal_code: string = "";
  secureat_licence: boolean = false;
  timezone: number = -1;
  task_generation_hour: number = 0;
  hidden_modules: DocumentReference[] = [];
  slow_internet_mode: boolean = false;
  audio_task_reminders: boolean = false;
  country: string = "";
  pattern: string | null = null;
  illustration_url: SecureatIllustration | null = null;
  utc_timezone: string | null = null;
  tags: DocumentReference[];
  koovea_sticker: string | null;
  licence_activation_history: ActionHistory[];
  hidden_extensions: string[] = [];
  manager_owned: boolean = false;
  maximum_devices: number = 1;
  devices: DocumentReference[] = [];

  constructor(
    ref: DocumentReference,
    active: boolean,
    address: string,
    city: string,
    creation_date: Date,
    database: DocumentReference | null,
    distributor: DocumentReference,
    name: string,
    pairing_key: string | null,
    postal_code: string,
    secureat_licence: boolean,
    timezone: number,
    task_generation_hour: number,
    hidden_modules: DocumentReference[],
    slow_internet_mode: boolean,
    audio_task_reminders: boolean,
    country: string = "",
    pattern: string | null,
    illustration_url: SecureatIllustration | null,
    utc_timezone: string | null,
    tags: DocumentReference[],
    koovea_sticker: string,
    licence_activation_history: ActionHistory[],
    hidden_extensions: string[] = [],
    manager_owned: boolean = false,
    maximum_devices: number = 1,
    devices: DocumentReference[] = []
  ) {
    super(ref);
    this.active = active;
    this.address = address;
    this.city = city;
    this.creation_date = creation_date;
    this.database = database;
    this.distributor = distributor;
    this.name = name;
    this.pairing_key = pairing_key;
    this.postal_code = postal_code;
    this.timezone = timezone;
    this.secureat_licence = secureat_licence;
    this.country = country;
    this.hidden_modules = hidden_modules;
    this.task_generation_hour = task_generation_hour;
    this.slow_internet_mode = slow_internet_mode;
    this.audio_task_reminders = audio_task_reminders;
    this.pattern = pattern;
    this.illustration_url = illustration_url;
    this.utc_timezone = utc_timezone;
    this.tags = tags;
    this.koovea_sticker = koovea_sticker;
    this.licence_activation_history = licence_activation_history;
    this.hidden_extensions = hidden_extensions;
    this.manager_owned = manager_owned;
    this.maximum_devices = maximum_devices;
    this.devices = devices;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Store {
    const data = snapshot.data()!;

    let result = new Store(
      snapshot.ref,
      data.active,
      data.address,
      data.city,
      data.creation_date,
      data.database == undefined ? null : data.database,
      data.distributor,
      data.name,
      data.pairing_key == undefined ? null : data.pairing_key,
      data.postal_code,
      data.secureat_licence,
      data.timezone,
      data.task_generation_hour == undefined ? 0 : data.task_generation_hour,
      data.hidden_modules == undefined ? [] : data.hidden_modules,
      data.slow_internet_mode == undefined ? false : data.slow_internet_mode,
      data.audio_task_reminders == undefined
        ? false
        : data.audio_task_reminders,
      data.country == undefined ? "FR" : data.country,
      data.pattern == undefined ? null : data.pattern,
      data.illustration_url == undefined
        ? null
        : SecureatIllustration.fromFirestore(data.illustration_url),
      data.utc_timezone != undefined ? data.utc_timezone : null,
      data.tags == undefined ? [] : data.tags,
      data.koovea_sticker == undefined ? null : data.koovea_sticker,
      data.licence_activation_history == undefined
        ? []
        : data.licence_activation_history.map((x) =>
            ActivationHistory.fromFirestore(x)
          ),

      data.hidden_extensions == undefined ? [] : data.hidden_extensions,
      data.manager_owned == undefined ? false : data.manager_owned,
      data.maximum_devices == undefined ? 1 : data.maximum_devices,
      data.devices == undefined ? [] : data.devices
    );

    return result;
  }

  public toFirestore() {
    var result = {
      active: this.active,
      address: this.address,
      city: this.city,
      creation_date: this.creation_date,
      database: this.database,
      distributor: this.distributor,
      name: this.name,
      pairing_key: this.pairing_key,
      postal_code: this.postal_code,
      secureat_licence: this.secureat_licence,
      timezone: this.timezone,
      task_generation_hour: this.task_generation_hour,
      hidden_modules: this.hidden_modules,
      slow_internet_mode: this.slow_internet_mode,
      country: this.country,
      audio_task_reminders: this.audio_task_reminders,
      pattern: this.pattern,
      illustration_url: this.illustration_url
        ? this.illustration_url.toFirestore()
        : null,
      utc_timezone: this.utc_timezone,
      tags: this.tags,
      koovea_sticker: this.koovea_sticker,
      licence_activation_history: this.licence_activation_history.map((x) =>
        x.toFirestore()
      ),
      hidden_extensions: this.hidden_extensions,
      manager_owned: this.manager_owned,
      maximum_devices: this.maximum_devices,
      devices: this.devices,
    };

    return result;
  }
  static clone(store: Store): Store {
    return new Store(
      store.ref,
      store.active,
      store.address,
      store.city,
      store.creation_date,
      store.database,
      store.distributor,
      store.name,
      store.pairing_key,
      store.postal_code,
      store.secureat_licence,
      store.timezone,
      store.task_generation_hour,
      store.hidden_modules,
      store.slow_internet_mode,
      store.audio_task_reminders,
      store.country,
      store.pattern,
      store.illustration_url == null ? null : store.illustration_url.clone(),
      store.utc_timezone,
      store.tags,
      store.koovea_sticker,
      store.licence_activation_history,
      store.hidden_extensions.map((x) => x),
      store.manager_owned,
      store.maximum_devices,
      store.devices
    );
  }
}
