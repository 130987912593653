import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-010c2839"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex col align-center grow p12 justify-center g24",
  style: {"text-align":"center"}
}
const _hoisted_2 = ["aria-valuenow"]
const _hoisted_3 = { class: "flex col align-center g0" }
const _hoisted_4 = {
  class: "bold",
  style: {"font-size":"42px"}
}
const _hoisted_5 = { class: "text-muted" }
const _hoisted_6 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SeModal, {
      ref: "scoreModal",
      title: "About score"
    }, null, 512),
    _createVNode(_component_Panel, { title: "Restaurant Score" }, {
      header: _withCtx(() => [
        _createVNode(_component_Badge, { color: "#5ebd44" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("home.strong")), 1)
          ]),
          _: 1
        })
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "circular-progress",
            "aria-valuenow": _ctx.finalScore / 2,
            "aria-valuemin": "0",
            "aria-valuemax": "100",
            style: _normalizeStyle('--value:' + _ctx.finalScore / 2)
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.finalScore), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.Lang.getI18N("out-of-100")), 1)
            ])
          ], 12, _hoisted_2),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.Lang.getI18N("home.scoreDesc")), 1),
          _createVNode(_component_Button, {
            onClick: _ctx.onLearnMoreClick,
            class: "w100"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("learn-more")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    })
  ], 64))
}