
import { getStoreManager } from "@/firebase/StoreManager";
import { getHomeState } from "@/pinia/home/HomeState";
import { defineComponent } from "vue";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import Score from "@/model/Score";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  name: "RestaurantScore",

  methods: {
    onLearnMoreClick() {
      var refs = <any>this.$refs;

      refs.scoreModal.open();
    },
  },
  async mounted() {
    var scores = await SecureatServerApi.getScores(
      this.homeState.selectedStore.ref.id
    );

    this.finalScore = Score.computeWeeklyScore(scores);

    if (!this.finalScore) {
      this.finalScore = 0;
    }
  },
  data() {
    return {
      Lang: Lang,
      finalScore: 0,
    };
  },
  setup() {
    var homeState = getHomeState();

    return {
      homeState: homeState,
    };
  },
});
