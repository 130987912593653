
import SeCalendarRanged from "@/components/global/SeCalendarRanged.vue";
import { getDistributorManager } from "@/firebase/DistributorManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Distributor from "@/model/Distributor";
import Employee from "@/model/Employees/Employee";
import PunchCardPair from "@/model/Employees/PunchCardPair";
import { getHomeState } from "@/pinia/home/HomeState";
import { defineComponent } from "vue";
import * as DateUtils from "@/utils/DateUtils";
import SeDropdown from "@/components/global/dropdown/SeDropdown.vue";
import ReadonlyField from "@/components/global/ReadonlyField.vue";
import PunchCardEntry from "@/model/Employees/PunchCardEntry";
import * as Snackbars from "@/utils/Snackbars";
import PunchCardExporter from "@/model/Employees/PunchCardExporter";
import { CSVUtils } from "@/utils/CSVUtils";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  async mounted() {
    this.search();
  },

  setup() {
    return {
      homeState: getHomeState(),
    };
  },
  methods: {
    getBadgeClass(entry: PunchCardEntry, group: PunchCardEntry[]) {
      if (group.length <= 1) {
        return "";
      }

      if (group.indexOf(entry) == 0) {
        return "badge-left";
      } else {
        return "badge-right";
      }
    },
    exportCSV() {
      var header = ["Etablissement", "Employee", "Date", "Time", "Arrivée"];

      var data = [] as string[][];

      data.push(header);

      for (let employee of this.getEmployees()) {
        var card = getHomeState().getEmployeePunchCard(employee);

        for (let storeId of card.data.keys()) {
          var store = getStoreManager().getStoreFromCacheOnly(storeId);

          for (let values of card.data.get(storeId)) {
            for (let entry of values[1]) {
              var row = [] as string[];

              row.push(store.name);
              row.push(employee.name + " " + employee.surname);
              row.push(values[0]);
              row.push(DateUtils.formatTime(entry.getTimestamp()));
              row.push(entry.is_in ? "true" : "false");

              data.push(row);
            }
          }
        }
      }

      var result = CSVUtils.writeCSVFile(data, ";", true);

      CSVUtils.downloadCSV(result, "punch_cards.csv");
    },
    getGroupedEntries(employee: Employee, date: Date) {
      var entries = this.homeState.getPunchCardEntries(employee, date);

      entries.sort(
        (a, b) => a.getTimestamp().getTime() - b.getTimestamp().getTime()
      );

      var result = [] as PunchCardEntry[][];
      let i = 0;

      while (i < entries.length) {
        const currentEntry = entries[i];

        if (currentEntry.is_in) {
          if (i + 1 < entries.length && !entries[i + 1].is_in) {
            result.push([currentEntry, entries[i + 1]]);
            i += 2;
          } else {
            result.push([currentEntry]);
            i++;
          }
        } else {
          result.push([currentEntry]);
          i++;
        }
      }

      return result;
    },
    deletePunchCard() {
      getHomeState().deletePunchCard(
        this.selectedEmployee,
        this.selectedDate,
        this.selectedEntry
      );

      getHomeState().deletePunchCard(
        this.selectedEmployee,
        this.selectedDate,
        this.selectedEntry
      );

      this.$refs.editModal.close();

      this.selectedEmployee = null;
      this.selectedEntry = null;
      this.selectedDate = null;
    },
    addPunchCard(employee: Employee, date: Date) {
      getHomeState().addPunchCard(employee, date);
    },
    async save() {
      var refs = <any>this.$refs;

      for (let employee of this.getEmployees()) {
        var punchCard = getHomeState().getEmployeePunchCard(employee);

        punchCard.sort();

        await punchCard.set();
      }


      Snackbars.display("The punch cards have been saved");
    },
    closeEditModal() {
      var previous = this.homeState.getPreviousPunchCard(
        this.selectedEmployee,
        this.selectedEntry,
        this.selectedDate
      );

      if (this.selectedEntry.getTimestamp() <= previous.getTimestamp()) {
        this.errorMsg = "The new timestamp must be after the previous entry";
        return;
      }

      this.$refs.editModal.close();

      this.selectedEmployee = null;
      this.selectedEntry = null;
      this.selectedDate = null;
    },
    openEditModal(pair: PunchCardEntry, employee: Employee, date: Date) {
      this.selectedEmployee = employee;
      this.selectedEntry = pair;
      this.selectedDate = date;

      if (!this.selectedEntry.timestamp_modified) {
        this.selectedEntry.timestamp_modified = new Date(
          this.selectedEntry.timestamp
        );
      }

      this.$refs.editModal.open();
    },

    onModalClose() {
      this.selectedEmployee = null;
      this.selectedEntry = null;
      this.selectedDate = null;
    },

    getTotalHours() {
      var total = 0;
      for (let employee of this.getEmployees()) {
        total += this.computeTotalWorkingHours(employee);
      }
      return total;
    },
    getEmployees() {
      return this.homeState.employees.filter((x) => x.active);
    },
    computeTotalWorkingHours(employee: Employee) {
      let total = 0;
      return 99;
      /* for (let date of this.dates) {
        let pairs = this.homeState.getPunchCardPairs(employee, date);
        for (let pair of pairs) {
          total += pair.getDuration();
        }
      }
      return total; */
    },
    search() {
      var start = this.$refs.datePicker?.getStart();
      var end = this.$refs.datePicker?.getEnd();

      const currentDate = new Date(start);

      this.dates = [];

      while (currentDate <= end) {
        this.dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    },
  },
  data() {
    return {
      Lang: Lang,
      selectedEmployee: null as Employee | null,
      selectedEntry: null as PunchCardEntry | null,
      selectedDate: null as Date | null,
      errorMsg: null as string | null,
      dates: [] as Date[],
      DateUtils: DateUtils,
    };
  },
});
